<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" back="Organisations">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-box>

			<app-input-text v-model="model.name" :validation="$v.model.name" label="Name" placeholder="Enter name" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixins/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			model: {
				id: false,
				name: ''
			},
			endpoint: 'organisation',
			noun: 'organisation'
		}

	},

	validations: {
		model: {
			name: {
				required
			}
		}
	}

}

</script>

<style scoped>

</style>